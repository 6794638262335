import React from 'react';

class ExpandCollapseButtons extends React.Component {
    constructor(props) {
        super(props);
        this.handleExpandClick = this.handleClick.bind(this, true);
        this.handleCollapseClick = this.handleClick.bind(this, false);
    }

    handleClick(allOpen, e) {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        this.props.handleButtonClick(allOpen);
    }

    render() {
        return (
            <div className="button-wrapper">
                <button
                    className="btn btn-lg btn-expand-collapse btn-expand-all"
                    onClick={this.handleExpandClick}
                    aria-controls="accordion-group"
                    aria-label={this.props.expandText}
                >
                    {this.props.expandText}
                </button>

                <button
                    className="btn btn-lg btn-expand-collapse btn-collapse-all mr-0"
                    onClick={this.handleCollapseClick}
                    aria-controls="accordion-group"
                    aria-label={this.props.collapseText}
                >
                    {this.props.collapseText}
                </button>
            </div>
        );
    }
}

export default ExpandCollapseButtons;
